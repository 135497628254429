/* @import url('https://fonts.googleapis.com/css?family=Josefin+Sans'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400&display=swap');

:root {
	--light-orange: #dd8c65;
	--dark-orange: #de6f3a;
	--x-light-orange: #dca990;
	--gray-orange: #dcc7bb;
	--orange-bg: #fcfcfc;
}
 


html, body {
	font-family: 'Montserrat', sans-serif;
	background-color:  var(--orange-bg);
	
}

.top-nav {
	background-color:  var(--dark-orange);
	color: white;
	
}

.top-nav p {
	margin: 10px;
	display: inline-block;
	text-align: right;
	margin-right: 20px;
}

 .container-fluid-custom {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0;
 }

.hero.about {
    background: #222222 url('../assets/bg6.jpg?1484') top center no-repeat;
    background-size: cover;
    padding: 240px 0 200px;
}

.hero {
    color: black;
    display: table;
    width: 100%;
    padding: 240px 0 200px;
    text-align: left;
    position: relative;
}

.hero .center-vertically {
    display: table-cell;
    vertical-align: middle;
}

.hero.about .wrapper.main-content {
    padding: 0 30px;
}

.main-content {
    display: block;
    margin-top: 0;
}
.wrapper {
    *zoom: 1;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 30px;
}

.custom-fluid-bg {
    background-color: var(--gray-orange);
    padding-top: 20px;
    padding-bottom: 20px;
}
.card-body-detail {
    min-height: 360px;
}

footer {
    margin: 30px 0; 
    padding: 25px 0;
    
    height: 100px;
}

/* .card {
    height: 400px !important;
} */